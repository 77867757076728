<template>
  <div>

    <div class="flex flex-wrap items-center justify-between mx-5">
      <vx-input-group class="mb-base mr-3">
      </vx-input-group>
      <div class="flex items-center">
        <vs-button class="mb-base mr-3" @click="createAdmin">Create Admin</vs-button>
      </div>
    </div>


    <vx-card title="">
      <vs-table max-items="5" :pagination="admins.length > 0" :data="admins">
        <template slot="thead">
          <vs-th>Full Name</vs-th>
          <vs-th>Email</vs-th>
          <vs-th>Reset Password</vs-th>
          <vs-th>Status</vs-th>
          <vs-th class="justify-center">Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.fullName">
              {{ tr.fullName }}
            </vs-td>
            <vs-td :data="tr.email">
              {{ tr.email }}
            </vs-td>

            <vs-td :data="tr._id">
              <a href="#" @click="resetPassword(tr._id, tr)">Reset Password</a>
            </vs-td>
            <vs-td :data="tr._id">
              {{ tr.accountStatus == "Active" ? "Enabled" : "Disabled" }}
            </vs-td>
            <vs-td class="whitespace-no-wrap text-center">
              <a href="#" @click.stop="editAdminUser(tr._id)">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"/>
              </a>
              <a href="#" @click.stop="deleteAdminUser(tr._id)">
                <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"/>
              </a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'

export default {
  data() {
    return {
      activeConfirm: false,
      adminUserId: null,
      admins: []
    };
  },
  methods: {
    ...mapActions("adminUser", [
      "fetchAdminUsers",
      "removeAdminUser",
      "resetAdminUserPassword"
    ]),
    getAdminsList() {
      this.$vs.loading()
      this.fetchAdminUsers().then(res => {
        this.$vs.loading.close()
        this.admins = res.data.data
      });
    },
    editAdminUser(id) {
      this.$router.push({name: 'edit-admin', params: {id}});
    },
    createAdmin() {
      this.$router.push({name: 'create-admin'});
    },
    deleteAdminUser(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.acceptDeleteAlert(id)
        }
      })

    },
    acceptDeleteAlert(id) {
      this.$vs.loading()
      this.removeAdminUser(id)
        .then(() => {
          this.$vs.loading.close()
          this.adminUserId = null;
          this.$vs.notify({
            title: 'Success',
            text: "User Deleted Successfully.",
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })
    },
    showChangePasswordSuccess() {
      this.$vs.notify({
        title: 'Success',
        text: "Reset password mail sent to user.",
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success'
      })
    },
    resetPassword(id, user) {
      if (user.accountStatus === 'Inactive') {
        this.errorMessage('Cannot reset password for disabled user. Please enable the user to reset the password.')
      } else {
        Swal.fire({
          title: 'Are you sure?',
          text: "Are you sure you want reset password for this user?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes!'
        }).then((result) => {
          if (result.value) {
            this.acceptPasswordResetAlert(id)
          }
        })
      }
    },
    acceptPasswordResetAlert(id) {
      this.resetAdminUserPassword(id)
        .then(() => {
          this.showChangePasswordSuccess()
        })
        .catch(err => {
          console.error(err)
        })
    },
    errorMessage(message) {
      this.$vs.notify({
        title: 'Warning',
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
    }
  },
  created: function () {
    this.getAdminsList();
  },
}
</script>
